
export function fixDates(objectList: any[]) {
    for (let obj of objectList) {
        for (let prop in obj) {
            if (obj.hasOwnProperty(prop) && prop.endsWith('Date')) {
                obj[prop] = new Date(obj[prop]);
            }
        }
    }
}

const f = Math.floor;

export function formatAgeRange(minAgeInMonths: number, maxAgeInMonths: number): string {
    if (!maxAgeInMonths) {
        if (minAgeInMonths % 12 === 0) {
            return f(minAgeInMonths / 12) + '+ years'
        } else {
            return minAgeInMonths + '+ months'
        }
    } else if (minAgeInMonths < 36 &&  maxAgeInMonths <= 48) {
        return minAgeInMonths + ' - ' + maxAgeInMonths + ' months';
    } else if (minAgeInMonths % 12 === 0 && maxAgeInMonths % 12 === 11) {
        let min = f(minAgeInMonths / 12);
        let max = f(maxAgeInMonths / 12);
        return min !== max ?
            min + ' - ' + max + ' years' :
            min + ' years';

    } else {
        let [min, minunit] = minAgeInMonths % 12 === 0 ? [f(minAgeInMonths / 12), ' years'] : [minAgeInMonths, ' months'];
        let [max, maxunit] = maxAgeInMonths % 12 === 11 ? [f(maxAgeInMonths / 12), ' years'] : [maxAgeInMonths, ' months'];

        return min + (minunit !== maxunit ? ' ' + minunit : '') + ' - '
            + max + ' ' + maxunit;
    }
}