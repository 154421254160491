





































































































































































































































































































import * as http from './http-util';
import Vue from 'vue';
import Component from 'vue-class-component';
import * as model from '../common/models';
import * as qs from 'qs';
import {Watch} from 'vue-property-decorator';
import * as util from './util';

interface CourseExt extends model.Course
{
    startSeason: number;
    endSeason: number;
    hasStarted: boolean;
    ageRange: string;
    dateRange: string;
}

interface ActivityGroup
{
    id: number;
    name: string;
    description: string;
    courseList: CourseExt[];
}

interface CategoryGroup
{
    name: string;
    activityGroupList: ActivityGroup[]
}

interface Filter
{
    season: number;
    includeClosedRegistration: boolean;
    includeWaitlist: boolean;
}

const seasonNameMap: {[trimester: number]: string} = {
    0: "Winter",
    1: "Spring",
    2: "Summer",
    3: "Fall"
};

//const trimesterMap: {[seasonName: string]: number} = Object.keys(seasonNameMap).reduce((m, i) => {  m[seasonNameMap[i]] = Number(i); return m; }, {});

@Component({
    name: "RegisteredProgramsApp",
    components: {},
    props: {}
})
export default class RegisteredProgramsApp extends Vue {
    editTopFilter = true;
    //programData: model.RegisteredProgramsData;
    postalCode: string = '';
    distanceInKms = 2;
    age: number = null;
    ageMultiplier = 12;
    categoryGroupList: CategoryGroup[] = null;
    filteredCategoryGroupList: CategoryGroup[] = [];
    expandedCategories: {[name: string]: boolean} = {};
    expandedActivities: {[activityId: number]: boolean} = {};
    locationMap: {[locationId: number]: model.LocationWithDistance} = {};
    filter: Filter = { season: 0, includeClosedRegistration: false, includeWaitlist: true };
    seasonOptions: {id: number, label: string}[] = [];
    loading = false;

    async created() {
    }

    async findPrograms() {
        if (this.postalCode.trim() === '' /* || ! this.age */) {
            let s = '';
            if (this.postalCode.trim() === '') {
                s = "Postal code missing.  "
            }
            // additional validation would go here
            alert(s);
            return;
        }
        try {
            this.loading = true;
            let programData = await http.get<model.RegisteredProgramsDataForUI>('/data?' +
                qs.stringify({distance: this.distanceInKms, postalCode: this.postalCode, age: this.age ? this.age * this.ageMultiplier : undefined}));
            programData.currentDate = new Date(<any>programData.currentDate);

            let activityGroupMap: {[activityId: number]: ActivityGroup} = {};

            let categoryGroupMap: {[name: string]: ActivityGroup[]} = programData.activityList.reduce((m, i) => {
                let categoryName = i.category;
                let arr = m[categoryName];
                let g: ActivityGroup = {
                    id: i.id,
                    name: i.title,
                    description: i.description,
                    courseList: []
                };
                activityGroupMap[i.id] = g;
                if (arr) {
                    arr.push(g)
                } else {
                    m[categoryName] = [g];
                }
                return m;
            }, {});

            let categoryGroupList = Object.keys(categoryGroupMap).sort().map((sc) => {
                let g: CategoryGroup = {
                    name: sc,
                    activityGroupList: categoryGroupMap[sc]
                };
                return g;
            });

            let seasonsWithMatchingCourses: Set<number> = new Set<number>();

            this.filter.season = null;
            let currentDate = programData.currentDate;
            let currentSeason = currentDate.getFullYear() * 10 + Math.floor(currentDate.getMonth() / 3);
            let seasonSet: Set<number> = new Set([currentSeason]);

            for (let course of programData.courseList) {
                // fix json dates
                course.startDate = new Date(<any>course.startDate);
                course.endDate = new Date(<any>course.endDate);
                course.registrationStartDate = new Date(<any>course.registrationStartDate);

                let activityGroup = activityGroupMap[course.activityId];
                let courseExt = <CourseExt> course;
                courseExt.ageRange = util.formatAgeRange(course.minAgeMonths, course.maxAgeMonths);
                courseExt.hasStarted = course.startDate.getTime() > programData.currentDate.getTime();
                courseExt.dateRange = course.startDate.toISOString().substring(0, 10) + ' to '
                                       + course.endDate.toISOString().substring(0, 10);
                let startTrimester = Math.floor(course.startDate.getMonth() / 3);
                courseExt.startSeason = course.startDate.getFullYear() * 10 + startTrimester;
                let endTrimester = Math.floor(course.endDate.getMonth() / 3);
                courseExt.endSeason = course.endDate.getFullYear() * 10 + endTrimester;
                if (courseExt.startSeason >= currentSeason) seasonSet.add(courseExt.startSeason);
                activityGroup.courseList.push(courseExt);
                if (this.filterCourse(courseExt)) {
                    seasonsWithMatchingCourses.add(courseExt.startSeason);
                }
                if (course.startDate.getTime() < programData.currentDate.getTime()) {
                    courseExt.registrationStatus = 'CLOSED';
                }
            }

            this.seasonOptions = Array.from(seasonSet).sort().map((s) => { return { id: s, label: Math.floor(s / 10) + ' ' + seasonNameMap[s % 10]}});

            let twoMonthsFromNow = new Date(); twoMonthsFromNow.setMonth(twoMonthsFromNow.getMonth() + 2);

            this.filter.season = twoMonthsFromNow.getFullYear() * 10 + Math.floor(twoMonthsFromNow.getMonth() / 3);

            for (let location of programData.locationList) {
                this.locationMap[location.id] = location;
            }

            this.categoryGroupList = categoryGroupList;

            Vue.set(this, 'expandedActivities', {});

            this.editTopFilter = false;
            this.loading = false;
        } catch (err) {
            this.loading = false
            alert("Unable to find programs: " + err.message)
        }

    }

    filterCourse(course: CourseExt) {
        return (!this.filter.season ||
            (this.filter.season >= course.startSeason && this.filter.season <= course.endSeason)) &&
            (this.filter.includeWaitlist || course.registrationStatus !== 'WAITLIST') &&
            (this.filter.includeClosedRegistration || course.registrationStatus !== 'CLOSED');
    }

    @Watch('filter', { immediate: true, deep: true })
    applyFilter() {
        if (!this.categoryGroupList) return;

        this.filteredCategoryGroupList = [];

        for (let categoryGroup of this.categoryGroupList) {
            let filteredActivityGroups: ActivityGroup[] = [];

            for (let activityGroup of categoryGroup.activityGroupList) {
                let filteredCourses = activityGroup.courseList.filter((c) => { return this.filterCourse(c); });
                if (filteredCourses.length > 0) {
                    filteredActivityGroups.push({
                        id: activityGroup.id,
                        name: activityGroup.name,
                        description: activityGroup.description,
                        courseList: filteredCourses
                    });
                }
            }

            if (filteredActivityGroups.length > 0) {
                this.filteredCategoryGroupList.push({
                    name: categoryGroup.name,
                    activityGroupList: filteredActivityGroups
                });
            }
        }
    }

    toggleActivity(activityId: number) {
        Vue.set(this.expandedActivities, ""+activityId, !this.expandedActivities[activityId]);
    }

    toggleCategory(categoryName: string) {
        Vue.set(this.expandedCategories, categoryName, !this.expandedCategories[categoryName]);
    }
}
