var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _vm.editTopFilter
        ? _c("span", [
            _vm._v(
              "This tool is designed to help you find city of Toronto programs that are offered close\n            to where you live."
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm._m(0)
    ]),
    _vm._v(" "),
    _c("fieldset", [
      _c("legend", [_vm._v("Search")]),
      _vm._v(" "),
      _vm.editTopFilter
        ? _c("div", [
            _c("table", { staticClass: "search" }, [
              _c("tr", [
                _c("td", [_vm._v("Postal Code:")]),
                _vm._v(" "),
                _c("td", [
                  _vm.editTopFilter
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.postalCode,
                            expression: "postalCode"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "postal-code",
                          autocomplete: "on"
                        },
                        domProps: { value: _vm.postalCode },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.findPrograms($event)
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.postalCode = $event.target.value
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.editTopFilter
                    ? _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.postalCode.toUpperCase())
                        }
                      })
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("Max distance in km:")]),
                _vm._v(" "),
                _c("td", [
                  _vm.editTopFilter
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.distanceInKms,
                            expression: "distanceInKms"
                          }
                        ],
                        attrs: { type: "number", name: "distance" },
                        domProps: { value: _vm.distanceInKms },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.findPrograms($event)
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.distanceInKms = $event.target.value
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.editTopFilter
                    ? _c("span", {
                        domProps: { textContent: _vm._s(_vm.distanceInKms) }
                      })
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("Age:")]),
                _vm._v(" "),
                _c("td", [
                  _vm.editTopFilter
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.age,
                            expression: "age"
                          }
                        ],
                        attrs: {
                          type: "number",
                          name: "age",
                          autocomplete: "on"
                        },
                        domProps: { value: _vm.age },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.findPrograms($event)
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.age = $event.target.value
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editTopFilter
                    ? _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.ageMultiplier,
                              expression: "ageMultiplier"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.ageMultiplier = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "12" } }, [
                            _vm._v("years")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "1" } }, [
                            _vm._v("months")
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.editTopFilter && _vm.age
                    ? _c("span", { domProps: { textContent: _vm._s(_vm.age) } })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.editTopFilter && _vm.age
                    ? _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.ageMultiplier == 12 ? "years" : "months"
                          )
                        }
                      })
                    : _vm._e()
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.editTopFilter
              ? _c("button", { on: { click: _vm.findPrograms } }, [
                  _vm._v("Find")
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.editTopFilter
              ? _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        _vm.editTopFilter = true
                      }
                    }
                  },
                  [_vm._v("Change")]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.editTopFilter
        ? _c("div", [
            _c("span", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v("Postal Code:")
            ]),
            _vm._v(" "),
            _c("span", {
              domProps: { textContent: _vm._s(_vm.postalCode.toUpperCase()) }
            }),
            _vm._v(" "),
            _c("span", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v("Maximum distance in km:")
            ]),
            _vm._v(" "),
            _c("span", {
              domProps: { textContent: _vm._s(_vm.distanceInKms) }
            }),
            _vm._v(" "),
            _vm.age
              ? _c("span", { staticStyle: { "font-weight": "bold" } }, [
                  _vm._v("Age")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.age
              ? _c("span", { domProps: { textContent: _vm._s(_vm.age) } })
              : _vm._e(),
            _vm._v(" "),
            _vm.age
              ? _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.ageMultiplier == 12 ? "years" : "months"
                    )
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                on: {
                  click: function($event) {
                    _vm.editTopFilter = true
                  }
                }
              },
              [_vm._v("Change")]
            )
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    !_vm.editTopFilter
      ? _c(
          "div",
          [
            _c("fieldset", [
              _c("legend", [_vm._v("Refine your search results")]),
              _vm._v(" "),
              _c("div", [
                _c("span", [_vm._v("Season:")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filter.season,
                        expression: "filter.season"
                      }
                    ],
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.filter,
                          "season",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.seasonOptions, function(season, index) {
                    return _c("option", {
                      domProps: {
                        value: season.id,
                        textContent: _vm._s(season.label)
                      }
                    })
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c("div", [
                _c("span", [_vm._v("Include full courses (waiting list):")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filter.includeWaitlist,
                      expression: "filter.includeWaitlist"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.filter.includeWaitlist)
                      ? _vm._i(_vm.filter.includeWaitlist, null) > -1
                      : _vm.filter.includeWaitlist
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.filter.includeWaitlist,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.filter,
                              "includeWaitlist",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.filter,
                              "includeWaitlist",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.filter, "includeWaitlist", $$c)
                      }
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", [
                _c("span", [_vm._v("Include courses that have started:")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filter.includeClosedRegistration,
                      expression: "filter.includeClosedRegistration"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.filter.includeClosedRegistration)
                      ? _vm._i(_vm.filter.includeClosedRegistration, null) > -1
                      : _vm.filter.includeClosedRegistration
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.filter.includeClosedRegistration,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.filter,
                              "includeClosedRegistration",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.filter,
                              "includeClosedRegistration",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.filter, "includeClosedRegistration", $$c)
                      }
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _vm._l(_vm.filteredCategoryGroupList, function(category, index) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "category-name",
                    class: _vm.expandedCategories[category.name]
                      ? "selected"
                      : "",
                    on: {
                      click: function($event) {
                        return _vm.toggleCategory(category.name)
                      }
                    }
                  },
                  [
                    _c("span", {
                      domProps: { textContent: _vm._s(category.name) }
                    }),
                    _vm._v(" "),
                    _vm.expandedCategories[category.name]
                      ? _c("span", { staticClass: "caret" }, [_vm._v("▲")])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.expandedCategories[category.name]
                      ? _c("span", { staticClass: "caret" }, [_vm._v("▼")])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _vm._l(category.activityGroupList, function(activity, index) {
                  return _vm.expandedCategories[category.name]
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            {
                              staticClass: "activity-name",
                              class: _vm.expandedActivities[activity.id]
                                ? "selected"
                                : "",
                              on: {
                                click: function($event) {
                                  return _vm.toggleActivity(activity.id)
                                }
                              }
                            },
                            [
                              _c("span", {
                                domProps: { textContent: _vm._s(activity.name) }
                              }),
                              _vm._v(" "),
                              _vm.expandedActivities[activity.id]
                                ? _c("span", { staticClass: "caret" }, [
                                    _vm._v("▲")
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.expandedActivities[activity.id]
                                ? _c("span", { staticClass: "caret" }, [
                                    _vm._v("▼")
                                  ])
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _vm.expandedActivities[activity.id]
                            ? [
                                _c("div", {
                                  domProps: {
                                    textContent: _vm._s(activity.description)
                                  }
                                }),
                                _vm._v(" "),
                                _vm.expandedActivities[activity.id]
                                  ? _c(
                                      "table",
                                      { staticClass: "courses" },
                                      [
                                        _vm._m(1, true),
                                        _vm._v(" "),
                                        _vm._l(activity.courseList, function(
                                          course,
                                          index
                                        ) {
                                          return _c("tr", [
                                            _c("td", [
                                              _c("a", {
                                                attrs: {
                                                  href:
                                                    "https://efun.toronto.ca/torontofun/Activities/ActivitiesCourseDetails.asp?aid=" +
                                                    course.activityId +
                                                    "&cid=" +
                                                    course.id,
                                                  target: "_blank",
                                                  rel: "noopener noreferrer"
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    course.barcode
                                                  )
                                                }
                                              })
                                            ]),
                                            _vm._v(" "),
                                            _c("td", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  course.title
                                                )
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("td", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  course.dateRange
                                                )
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("td", {
                                              domProps: {
                                                textContent: _vm._s(course.days)
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("td", {
                                              domProps: {
                                                textContent: _vm._s(course.time)
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("td", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  course.ageRange
                                                )
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("td", {
                                              staticStyle: {
                                                "text-align": "right"
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  course.fees ||
                                                    course.fees === 0
                                                    ? "$" + course.fees
                                                    : ""
                                                )
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c("a", {
                                                attrs: {
                                                  href:
                                                    "https://www.toronto.ca/data/parks/prd/facilities/complex/" +
                                                    course.locationId +
                                                    "/index.html",
                                                  target: "_blank",
                                                  rel: "noopener noreferrer"
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.locationMap[
                                                      course.locationId
                                                    ].name
                                                  )
                                                }
                                              }),
                                              _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    " (" +
                                                      _vm.locationMap[
                                                        course.locationId
                                                      ].distanceInKm.toFixed(
                                                        1
                                                      ) +
                                                      " km)"
                                                  )
                                                }
                                              })
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c("a", {
                                                attrs: {
                                                  href:
                                                    "https://efun.toronto.ca/torontofun/Activities/ActivitiesCourseDetails.asp?aid=" +
                                                    course.activityId +
                                                    "&cid=" +
                                                    course.id,
                                                  target: "_blank",
                                                  rel: "noopener noreferrer"
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    course.registrationStatus ===
                                                      "PENDING"
                                                      ? "Starts on " +
                                                          course.registrationStartDate
                                                            .toISOString()
                                                            .substring(0, 10)
                                                      : course.registrationStatus
                                                  )
                                                }
                                              }),
                                              _vm._v(" "),
                                              course.registrationStatus ===
                                              "WAITLIST"
                                                ? _c("span", {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        "(" +
                                                          course.waitlistSize +
                                                          ")"
                                                      )
                                                    }
                                                  })
                                                : _vm._e()
                                            ])
                                          ])
                                        })
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e()
                })
              ]
            })
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "loading" }, [_vm._v("Loading…")])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("Once you have found courses, visit "),
      _c(
        "a",
        {
          attrs: {
            href: "https://efun.toronto.ca",
            rel: "noopener noreferrer",
            target: "_blank"
          }
        },
        [_vm._v("Toronto FUN Online")]
      ),
      _vm._v(" to register using the barcodes.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("barcode")]),
      _vm._v(" "),
      _c("th", [_vm._v("title")]),
      _vm._v(" "),
      _c("th", [_vm._v("dates")]),
      _vm._v(" "),
      _c("th", [_vm._v("days")]),
      _vm._v(" "),
      _c("th", [_vm._v("time")]),
      _vm._v(" "),
      _c("th", [_vm._v("ages")]),
      _vm._v(" "),
      _c("th", [_vm._v("fees")]),
      _vm._v(" "),
      _c("th", [_vm._v("location")]),
      _vm._v(" "),
      _c("th", [_vm._v("registration")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }