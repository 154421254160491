import axios, { AxiosResponse } from 'axios';
import * as model from '../common/models';

let handlerMap: {[status: number]: () => void} = {};

async function sendRequest<T>(fn: () => Promise<AxiosResponse>) {
    try {
        let axiosResponse = await fn();
        if (axiosResponse.status >= 200 && axiosResponse.status < 300) {
            let response: model.Response<T> = axiosResponse.data;
            if (response.successful) {
                return response.value;
            } else {
                throw { message: response.message };
            }
        } else {
            let handler = handlerMap[axiosResponse.status];
            if (handler) handler();
            throw {message: axiosResponse.statusText, status: axiosResponse.status};
        }
    } catch (err) {
        if (err.response) {
            let handler = handlerMap[err.response.status];
            if (handler) handler();
        }
        console.log("Error here", err);
        throw err;
    }
}

export function setHandler(statusCode: number, handler: () => void) {
    handlerMap[statusCode] = handler;
}

export async function get<T>(url: string): Promise<T> {
    return sendRequest<T>(() => { return axios.get(url); });
}

export async function getRaw<T>(url: string): Promise<T> {
    try {
        let axiosResponse = await axios.get(url);
        if (axiosResponse.status >= 200 && axiosResponse.status < 300) {
            let response: T = axiosResponse.data;
            return response;
        } else {
            let handler = handlerMap[axiosResponse.status];
            if (handler) handler();
            throw {message: axiosResponse.statusText, status: axiosResponse.status};
        }
    } catch (err) {
        if (err.response) {
            let handler = handlerMap[err.response.status];
            if (handler) handler();
        }
        console.log("Error here", err);
    }
}

export async function put<T>(url: string, data: any): Promise<T> {
    return sendRequest<T>(() => { return axios.put(url, data); });
}

export async function post<T>(url: string, data?: any): Promise<T> {
    return sendRequest<T>(() => { return axios.post(url, data); });
}

export async function postRaw<T>(url: string, data: any): Promise<T> {
    try {
        let axiosResponse = await axios.post(url, data);
        if (axiosResponse.status >= 200 && axiosResponse.status < 300) {
            let response: T = axiosResponse.data;
            return response;
        } else {
            let handler = handlerMap[axiosResponse.status];
            if (handler) handler();
            throw {message: axiosResponse.statusText, status: axiosResponse.status};
        }
    } catch (err) {
        if (err.response) {
            let handler = handlerMap[err.response.status];
            if (handler) handler();
        }
        console.log("Error here", err);
        throw err;

    }
}

async function del<T>(url: string): Promise<void> {
    return sendRequest<void>(() => { return axios.delete(url); });
}

export { del as delete };